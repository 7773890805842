import React, { useState } from "react";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook, BiMessageSquareDetail } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";

const linkStyles =
  "bg-transparent p-3 rounded-[50%] text-white text-[1.1rem] hover:bg-[rgba(0,0,0,0.2) hover:text-gray-300";

const linkActive = "bg-gray-800 ";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");

  return (
    <nav
      className="flex gap-3 bg-[rgba(0,0,0,0.3)]  
    w-max py-3 px-7 z-50 fixed left-[20%] lg:left-[40%] bottom-8 rounded-full backdrop-blur-lg  
    "
    >
      <a
        href="#"
        className={`${linkStyles} ${activeNav === "#" ? linkActive : ""}`}
        onClick={() => setActiveNav("#")}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        className={`${linkStyles} ${activeNav === "#about" ? linkActive : ""} `}
        onClick={() => setActiveNav("#about")}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        className={`${linkStyles} ${
          activeNav === "#experience" ? linkActive : ""
        }`}
        onClick={() => setActiveNav("#experience")}
      >
        <BiBook />
      </a>
      <a
        href="#portfolio"
        className={`${linkStyles} ${
          activeNav === "#portfolio" ? linkActive : ""
        }`}
        onClick={() => setActiveNav("#portfolio")}
      >
        <RiServiceLine />
      </a>
      <a
        href="#contact"
        className={`${linkStyles} ${
          activeNav === "#contact" ? linkActive : ""
        }`}
        onClick={() => setActiveNav("#contact")}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Nav;
