import React from "react";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import { Me } from "../images";

const About = () => {
  return (
    <section
      id="about"
      className="p-10 h-auto flex flex-col items-center text-center mt-[8rem]"
    >
      <h5 className="text-gray-600">Get To Know</h5>
      <h2 className="lg:mb-12 sm:mb-8">About me</h2>
      <div className="grid lg:grid-cols-2 gap-[10%] grid-cols-1 ">
        <div className="w-[80%] m-8 aspect-square rounded-[2rem] gradient-bg-aboutme grid  ">
          <img
            src={Me}
            alt="aboutme"
            className="w-full rounded-3xl overflow-hidden rotate-12 transition duration-150 hover:rotate-0"
          />
        </div>

        {/** content */}
        <div className="">
          <div className="grid lg:grid-cols-3 lg:gap-6 gap-2 grid-cols-2">
            <article
              className="flex flex-col justify-center items-center
              bg-gradient-to-r from-violet-500 to-fuchsia-500  rounded-[1rem] p-8 hover:bg-transparent 
             hover:border-[1px] cursor-pointer transition duration-300"
            >
              <FaAward className="text-xl mb-4" />
              <h5 className="text-sm font-extrabold">Experience</h5>
              <small className="mt-1 text-xs text-gray-300 ">
                22 Years Working
              </small>
            </article>
            <article
              className="flex flex-col justify-center items-center
              bg-gradient-to-r from-violet-500 to-fuchsia-500  rounded-[1rem] p-8 hover:bg-transparent 
             hover:border-[1px] cursor-pointer transition duration-300"
            >
              <FiUsers className="text-xl mb-4" />
              <h5 className="text-sm font-extrabold">System Administrator</h5>
              <small className="mt-1 text-xs text-gray-300">15 years</small>
            </article>
            <article
              className="flex flex-col justify-center items-center
              bg-gradient-to-r from-violet-500 to-fuchsia-500  rounded-[1rem] p-8 hover:bg-transparent 
             hover:border-[1px] cursor-pointer transition duration-300"
            >
              <VscFolderLibrary className="text-xl mb-4" />
              <h5 className="text-sm font-extrabold">Projects</h5>
              <small className="mt-1 text-xs text-gray-300">
                e-commerce/more
              </small>
            </article>
          </div>
          <p className="text-gray-300 my-8 text-justify">
            I am a dynamic and proactive person with good communication and
            organization skills. I consider myself responsible and with a sense
            of results orientation. I like to work as a team and look for a new
            project that I can embrace and help to grow.
          </p>
          <a href="#contact" className="bg-fuchsia-500 rounded-sm p-2 ">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
