export const experiencelist = [
  {
    title: "Frontend Development",
    experience: [
      {
        Area: "HTML",
        Nivel: "Experienced",
      },
      {
        Area: "Tailwindcss",
        Nivel: "less 1 year",
      },
      {
        Area: "ReactJS",
        Nivel: "2 years",
      },
      {
        Area: "NextJS",
        Nivel: "less 1 years",
      },
      {
        Area: "MongoDB",
        Nivel: "2 years",
      },
    ],
  },
  {
    title: "Backend Development",
    experience: [
      {
        Area: "Django",
        Nivel: "less 4 year",
      },
      {
        Area: "Python",
        Nivel: "less 4 year",
      },
      {
        Area: "Node JS",
        Nivel: "1 years",
      },
      {
        Area: "MYSQL",
        Nivel: "less 1 years",
      },
      {
        Area: "django-rest-API",
        Nivel: "3 years",
      },
    ],
  },
  {
    title: "Cloud Services",
    experience: [
      {
        Area: "Linode",
        Nivel: "Experienced",
      },
      {
        Area: "Firebase",
        Nivel: "less 2 year",
      },
      {
        Area: "HyperV",
        Nivel: "Experienced",
      },
      {
        Area: "VMWare",
        Nivel: "Experienced",
      },
    ],
  },
  {
    title: "System Administrator",
    experience: [
      {
        Area: "Windows Server",
        Nivel: "Experienced",
      },
      {
        Area: "AD-DC/Exchange",
        Nivel: "Experienced",
      },
      {
        Area: "MSSQL",
        Nivel: "Experienced",
      },
      {
        Area: "Linux Centos/Ubuntu",
        Nivel: "Experienced",
      },
      {
        Area: "Nginx/Apache",
        Nivel: "Experienced",
      },
      {
        Area: "Coturn Server",
        Nivel: "less 1 years",
      },
    ],
  },
  {
    title: "Android/ iOS",
    experience: [
      {
        Area: "React Native",
        Nivel: "less 1 years",
      },
      {
        Area: "Expo",
        Nivel: "less 1 years",
      },
    ],
  },
  {
    title: "web3",
    experience: [
      {
        Area: "Metamask",
        Nivel: "less 1 years",
      },
      {
        Area: "NFTs",
        Nivel: "less 1 years",
      },
    ],
  },
];
