import React from "react";

import { BsYoutube, BsLinkedin } from "react-icons/bs";

const SocialsMedia = () => {
  return (
    <div className="lg:flex flex-col items-center gap-3 absolute l-0 bottom-12 text-fuchsia-600 hidden ">
      <a
        target="_blank"
        href="https://www.youtube.com/channel/UC6LbETxLZh9I5bmG7MCMhCQ"
        className="hover:text-gray-400"
      >
        <BsYoutube className="text-2xl" />
      </a>

      <a
        target="_blank"
        href="https://www.youtube.com/channel/UCzSUx4cbWPlqEnO6R7-pFjA"
        className="hover:text-gray-400"
      >
        <BsYoutube className="text-2xl" />
      </a>

      <a
        target="_blank"
        href="https://linkedin.com/in/jose-tavares-6085ba28"
        className="hover:text-gray-400"
      >
        <BsLinkedin className="text-2xl" />
      </a>
      <span class="after:content-[''] w-[1px] h-8 bg-fuchsia-600" />
    </div>
  );
};

export default SocialsMedia;
