import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_mlnra2i",
      "template_zdw359o",
      form.current,
      "yHqu4fihk5l06Cxph"
    );
    e.target.reset().then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  return (
    <section id="contact" className="p-10 h-auto flex flex-col items-center ">
      <h5 className="text-gray-600">Get in Touch </h5>
      <h2 className="m-2">Contact Me</h2>
      <div className="w-[100%] grid grid-cols-1 lg:grid-cols-2 gap-4 mt-[1rem]">
        <div className="flex flex-col gap-2 lg:w-[80%]  ">
          <article className="flex flex-col items-center bg-fuchsia-700 p-5 rounded-2xl hover:bg-transparent hover:border-[1px]  cursor-pointer">
            <MdOutlineEmail className="text-lg mb-2" />
            <h4>Email</h4>
            <h5 className="font-extralight">secatrom@gmail.com</h5>
            <a
              href="mailto:secatrom@gmail.com"
              target="_blank"
              className="mt-3 inline-block text-sm"
            >
              Send a message
            </a>
          </article>
          <article className="flex flex-col items-center bg-fuchsia-700 p-5 rounded-2xl hover:bg-transparent hover:border-[1px]  cursor-pointer">
            <RiMessengerLine className="text-lg mb-2" />
            <h4>Messenger</h4>
            <h5 className="font-extralight">jose.tavares.9803150</h5>
            <a
              href="https://m.me/jose.tavares.9803150"
              target="_blank"
              className="mt-3 inline-block text-sm"
            >
              Send a message
            </a>
          </article>
          <article className="flex flex-col items-center bg-fuchsia-700 p-5 rounded-2xl hover:bg-transparent hover:border-[1px]  cursor-pointer">
            <BsWhatsapp className="text-lg mb-2" />
            <h4>WhatsApp</h4>
            <h5 className="font-extralight">Phone</h5>
            <a href="" target="_blank" className="mt-3 inline-block text-sm">
              Send a message
            </a>
          </article>
        </div>
        <div className="p-5 flex flex-col justify-start items-center blue-glassmorphism">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-col gap-5 w-full"
          >
            <input
              type="text"
              name="name"
              placeholder="Your Full Name"
              required
              className="my-2 rounded-sm p-2 outline-none bg-transparent text-white border-none text-sm white-glassmorphism"
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className="my-2 w-full rounded-sm p-2 outline-none bg-transparent text-white border-none text-sm white-glassmorphism"
            />
            <textarea
              name="message"
              placeholder="Your Message"
              rows="7"
              required
              className="my-2 w-full rounded-sm p-2 outline-none bg-transparent text-white border-none text-sm white-glassmorphism"
            ></textarea>
            <button
              type="submit"
              className="text-white w-full mt-2 border-[1px] p-2 border-[#a91fcc] hover:bg-[#a91fcc] rounded-full cursor-pointer"
            >
              send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
