import React from "react";
import CardPortfolio from "./extras/CardPortfolio";
import { portfoliolist } from "./extras/portfolioData";

const Portfolio = () => {
  //console.log("dentro", portfoliolist);
  return (
    <section
      id="portfolio"
      className="p-10 h-auto flex flex-col items-center text-center "
    >
      <h5 className="text-gray-600">My Recent Work</h5>
      <h2 className="m-2">Portfolio</h2>
      <div className="mt-2 grid grid-cols-1 gap-6 w-[90%] lg:grid-cols-4 ">
        <CardPortfolio portfoliolist={portfoliolist} />
      </div>
    </section>
  );
};

export default Portfolio;
