import React from "react";
import { Logo } from "../images";

const Footer = () => {
  return (
    <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
      <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5 " />

      <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
        <img src={Logo} alt="logo" className="w-12" />

        <p className="text-white text-right text-xs">
          @tuawebmobile.com 2022 - All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
