import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaAndroid, FaApple } from "react-icons/fa";
import CardExperience from "./extras/CardExperience";
import { experiencelist } from "./extras/experienceData";

const Experience = () => {
  return (
    <section
      id="experience"
      className="p-10 h-auto flex flex-col items-center text-center mt-[8rem]"
    >
      <h5 className="text-gray-600">What Skills I Have</h5>
      <h2 className="m-2">My Experience</h2>
      <div className="m-2 grid lg:grid-cols-2 grid-cols-1 gap-8  w-full">
        <CardExperience experiencelist={experiencelist} />
      </div>
    </section>
  );
};

export default Experience;
