import React from "react";
import Cta from "./Cta";
import { Me, Logo } from "../images";
import SocialsMedia from "./SocialsMedia";

const Header = () => {
  return (
    <header className=" flex flex-col items-center justify-center h-[100vh] lg:h-[68vh] p-10 overflow-hidden ">
      <div className=" text-center h-[100%] relative w-full ">
        <h5>Hello I´m</h5>
        <h1 className="text-2xl">José Tavares</h1>
        <h5 className="text-gray-500">FullStack Developer</h5>
        <Cta />

        <SocialsMedia />

        <div
          className="absolute w-80 h-[30rem] left-[calc(50%-10rem)] 
        bg-gradient-to-r from-violet-500 to-fuchsia-500 mt-16 rounded-tr-[12rem]
        rounded-tl-[12rem] flex items-center justify-center"
        >
          <img
            src={Logo}
            alt="me"
            className=" w-[250px] h-[250px] pt-14 px-6 "
          />
        </div>

        <a
          href="#contact"
          className=" absolute -right-9 bottom-20 rotate-90 font-extralight text-sm hidden lg:block"
        >
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
