import React from "react";

import { CV_PT } from "../images";

const Cta = () => {
  return (
    <div className="mt-10 flex gap-5 justify-center">
      <a
        href={CV_PT}
        target="_blank"
        className="bg-black border-2  text-white rounded-md p-2"
      >
        Download CV
      </a>
      <a href="#contact" className="bg-fuchsia-400 text-white rounded-full p-2">
        Let's Talk
      </a>
    </div>
  );
};

export default Cta;
