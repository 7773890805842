export const portfoliolist = [
  {
    title: "Cryptoweb",
    description: "React with firebase and API",
    image: require("../../images/cryptowebsite.jpg"),
    href: "https://cryptoweb.tuawebmobile.com",
  },
  {
    title: "Diabeteweb",
    description: "React with firebase and API",
    image: require("../../images/diabetewebsite.jpg"),
    href: "https://diabeteweb.web.app/",
  },
  {
    title: "atl",
    description: "Django Linode Mysql",
    image: require("../../images/atlsite.jpg"),
    href: "http://atl.tuawebmobile.com/top/",
  },
  {
    title: "ChatPayFans",
    description: "React Firebase",
    image: require("../../images/chatpayfanssite.jpg"),
    href: "https://chatpayfans.firebaseapp.com/",
  },
  {
    title: "jollyhoo",
    description: "React Firebase",
    image: require("../../images/jollyhoosite.jpg"),
    href: "https://jollyhoo-66109.firebaseapp.com/",
  },
  {
    title: "e-commerce",
    description: "React Firebase",
    image: require("../../images/ecommercesite.jpg"),
    href: "http://lojaptmart.tuawebmobile.com/",
  },
  {
    title: "Site Multilanguage",
    description: "React Firebase",
    image: require("../../images/empresarialsite.jpg"),
    href: "https://exitomotrizlda-689da.web.app/",
  },
  {
    title: "Video Chat",
    description: "React Nginx Coturn Linode",
    image: require("../../images/cutornsite.jpg"),
    href: "https://coturn.tuawebmobile.com/",
  },
];
