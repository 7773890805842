import React from "react";

const CardPortfolio = ({ portfoliolist }) => {
  console.log("portfoliolist", portfoliolist);
  return (
    <>
      {portfoliolist.map((data, idx) => (
        <article
          key={idx}
          className="flex flex-col items-center 
        rounded-b-3xl 
        hover:bg-transparent 
         cursor-pointer transition duration-300"
        >
          <h3 className="my-5 font-bold">{data.title}</h3>
          <div className="bg-gradient-to-r from-violet-500 to-fuchsia-500">
            <img
              src={data.image}
              alt={data.title}
              className="rotate-12 w-[200px] h-[200px] hover:rotate-0 object-cover"
            />
          </div>
          <div className="flex flex-col items-center my-4">
            <p className="mb-2 font-extralight">{data.description}</p>
            <a
              href={data.href}
              target="_blank"
              className="p-3 bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-md"
            >
              Live Demo
            </a>
          </div>
        </article>
      ))}
    </>
  );
};

export default CardPortfolio;
