import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaAndroid, FaApple } from "react-icons/fa";

const CardExperience = ({ experiencelist }) => {
  return (
    <>
      {experiencelist.map((data, idx) => (
        <div
          key={idx}
          className="bg-fuchsia-700 px-9 py-2 rounded-3xl  
                     hover:bg-transparent 
                   hover:border-[1px] cursor-pointer transition duration-300  "
        >
          <h3 className="border-b-2 p-2 mb-3">{data.title}</h3>
          <div className="grid grid-cols-2 gap-y-8 ">
            {data.experience.map((dat, idx) => (
              <article className="flex gap-4">
                <BsPatchCheckFill className="mt-3 text-fuchsia-300" />
                <div className="flex flex-col items-start">
                  <h4>{dat.Area}</h4>
                  <small className="font-light">{dat.Nivel}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default CardExperience;
